import autoTable from 'jspdf-autotable';
import { jsPDF } from "jspdf";
import moment from 'moment'
import { getDataObtenerUno } from '../../api/common';
import 'moment/locale/es';
import { logoBiolab } from '../../helpers/imagen/Imagen';
moment().locale('es')



export const PdfIngreso = (infoActa)=> {
  var contador=0;
  var paginas=0;

  let footer=[[{content:'Observación', colSpan:10, styles:{halign:'center', fillColor:[34,171,180], 
  textColor:[0,0,0]}}]
]


  const equipos=infoActa.equipos
  const Cliente=infoActa.equipos[0].equipo.equipo.cliente
  let observacionTotal=''
  console.log(infoActa)

  let bodys=equipos.map(equipo=>[
    equipo.equipo.equipo.item.nombre, equipo.equipo.equipo.marca, equipo.equipo.equipo.modelo, equipo.equipo.equipo.serie, equipo.equipo.equipo.inventario, equipo.equipo.consecutivo, equipo.equipo.equipo.ubicacion, equipo.accesorios.length>0 ? 'X':'', equipo.accesorios.length>0 ? '':'X', equipo.accesorios.length>0 ? equipo.accesorios[0] : 'NINGUNO'],
    )

    bodys.push([{content:'Observación', colSpan:10, styles:{halign:'center', fillColor:[34,171,180], 
    textColor:[0,0,0]}}
  ])

  console.log(bodys)

  function unirobs(){
    var observaciones=''
    var j=1;
    console.log('uniendo observaciones')
    if(infoActa.observacion){
      var observaciones =j+") "+infoActa.observacion+".\n"
      j++
    }
    for(var i=0; i<infoActa.equipos.length; i++){
        console.log(infoActa.equipos[i]?.equipo)
      if(infoActa.equipos[i]?.observacion){
        var observaciones = observaciones +""+j +") " + infoActa.equipos[i]?.equipo?.equipo?.item.nombre +" - "+
        infoActa.equipos[i]?.equipo.consecutivo+" "+ infoActa.equipos[i]?.observacion+ ".\n"
        console.log('Adicionando al array de observaciones')
        bodys.push([
          {content:j +") " + infoActa.equipos[i]?.equipo?.equipo?.item.nombre +" - "+
          infoActa.equipos[i]?.equipo.consecutivo+" "+ infoActa.equipos[i]?.observacion
            , colSpan:10, styles:{fillColor:[255,255,255], valign:'middle', halign:'left', fontStyle:'normal'}}])
        j++;
      }
    }
    console.log(observaciones)
    infoActa.observacion=observaciones
    console.log(infoActa)
    return(observaciones)
  }
  unirobs();

  const doc = new jsPDF('l', 'cm', 'A4')
  async function getS() {
    console.log('entrando para crear pdf')
    const signDb = await getDataObtenerUno("sign", { user: infoActa.autor._id });
    if(signDb.status===200){
     crearpdf(signDb.data.data.sign)
    }
  }
  getS();

  console.log(footer)

const crearpdf = (firma)=>{
        doc.setFontSize(10)     
//---------->Informacion del cliente<-----------------
        autoTable(doc,{
            startY:2.8,
            theme:'plain',
            styles:{lineColor:10, lineWidth:0.01, fontSize:8, valign:'middle', halign:'center'}, 
            head:[
                [{content:'CLIENTE:', styles:{fillColor:[34,171,180], }},Cliente.nombre ,{content:'NIT:', styles:{fillColor:[34,171,180], }},Cliente.identificacion,{content:'TELEFONO: ', styles:{fillColor:[34,171,180], }},Cliente.telefono ,{content:'CONTACTO:', styles:{fillColor:[34,171,180], }},Cliente.contacto],
                [{content:'DIRECCIÓN: ', styles:{fillColor:[34,171,180], }},Cliente.direccion,{content:'FECHA RECIBIDO', styles:{fillColor:[34,171,180], cellWidth:3 }},moment(infoActa.fechaIngreso).format("YYYY-MM-DD") ,{content:'FECHA ENTREGA: ', styles:{fillColor:[34,171,180], cellWidth:3 }},moment(infoActa.fechaSalidaEstimada).format("YYYY-MM-DD"),{content:'PROPUESTA COMERCIAL: ', styles:{fillColor:[34,171,180], cellWidth:3}},infoActa.numeroOrden]
            ]
        })
//----------> contenido de equipos <------------------        
        autoTable(doc,{
            startY:4.8,
            theme:'plain',
            headStyles:{fontStyle:'bold', fillColor:[34,171,180], textColor:[0,0,0]},
            showHead:'everyPage',
            //showFoot:'lastPage',
            margin:{top:3, bottom:2.7},
            styles:{lineColor:10, lineWidth:0.01, fontSize:8, valign:'middle', halign:'center'}, 
            head: [
                [{content:'ÍTEM', rowSpan:2},{content:'MARCA', rowSpan:2}, {content:'MODELO', rowSpan:2},{content:'SERIE', rowSpan:2}, {content:'INVENTARIO', rowSpan:2},{content:'CONSECUTIVO ASIGNADO', rowSpan:2, styles:{cellWidth:2.5}}, {content:'UBICACIÓN', rowSpan:2}, {content:'ACCESORIOS', colSpan:3,}],
                [{content:'SI', styles:{cellWidth:1}},{content:'NO', styles:{cellWidth:1}}, {content:'¿CUALES?', styles:{cellWidth:4.5}}]
              ], 
            body:bodys,
            /*equipos.map(equipo=>[
                equipo.equipo.equipo.item.nombre, equipo.equipo.equipo.marca, equipo.equipo.equipo.modelo, equipo.equipo.equipo.serie, equipo.equipo.equipo.inventario, equipo.equipo.consecutivo, equipo.equipo.equipo.ubicacion, equipo.accesorios.length>0 ? 'X':'', equipo.accesorios.length>0 ? '':'X', equipo.accesorios.length>0 ? equipo.accesorios[0] : 'NINGUNO'],
                ),*/
            //foot:footer,
            
            didDrawPage: function (data) {
              //Encabezado
              autoTable(doc, {
                //theme:'plain',
                startY:0.5,
                showHead:'everyPage',
                headStyles:{fontStyle:'bold', fontSize:8},
                styles:{lineColor:10, lineWidth:0.02}, 
                theme:'plain',
                head: [[{  content : 'SISTEMA INTEGRADO DE GESTIÓN' ,  colSpan : 3 ,  rowSpan : 1 ,  styles : {  halign : 'center', cellWidth: 6, fillColor:[34,171,180] } }],
                      [{  content : '' ,  colSpan : 1 ,  rowSpan : 2, }, {content:'Proceso: Prestación de servicio de calibración', styles:{ halign:'center'}}, 'Código: MIS-PCB-FOR-003'],
                      [ {content:'Formato: Ingreso de Ítems para Calibración', styles:{ halign:'center'}}, 'Versión: 05'],
                      ],
                didDrawCell: (data) => {
                  if (data.section === 'head' && data.column.index === 0 && data.row.index===1) {
                    var base64Img = 
                    doc.addImage(logoBiolab, 'JPEG', data.cell.x+1.2, data.cell.y+0.1, 2.5, 0.8)
                  }},
              })

              //--------> Pie de pagina <---------------//
                autoTable(doc, {
                  startY:18.5,
                  //showHead:'everyPage',
                  footStyles:{ fillColor:[255,255,255], textColor:[0,0,0]},
                  styles:{lineColor:10, lineWidth:0.02, fontSize:8}, 
                  foot:[['Elaboro: Director de Laboratorio ','Revisó: Director de Calidad', 'Aprobó: Gerente'],],
                })
                        // Footer
                var str = "Página " + doc.internal.getNumberOfPages()
                doc.setFontSize(10);
                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                doc.setFontSize(8);
                console.log(str)
                paginas=doc.internal.getNumberOfPages()
                console.log(paginas)
                doc.text(str,data.settings.margin.right,28.5);
            }
        })
        //-----------> Fin Contenido <---------------//


        //--------> Espacio de firmas <----------------//

        autoTable(doc, {
            bodyStyles:{fillColor:[255,255,255], valign:'bottom', fontSize:8},
            theme:'plain',
            margin:{top:2, bottom:3},
            pageBreak:'avoid',
            body: [
              [{content:'Recibe:', styles:{cellWidth:3,}},{content:'', colSpan:2, styles:{cellWidth:6, minCellHeight:2.5}}],
              ['Nombre:',{content:infoActa.autor.nombre +' '+infoActa.autor.apellido, colSpan:2,}],
              ['Fecha y Hora:',moment(infoActa.fechaIngreso).format('lll')],
          ],
          didDrawCell:(data)=>{
            if(data.section==='body' && data.column.index === 1 && data.row.index === 1){
              var firmabase64Img=firma
              doc.addImage(firmabase64Img,'png',data.cell.x+0.6, data.cell.y-1.8,3, 2)
            }
            if(contador===0 && paginas<doc.internal.getNumberOfPages()){
              contador++;
              //Encabezado
              autoTable(doc, {
                //theme:'plain',
                startY:0.5,
                showHead:'everyPage',
                headStyles:{fontStyle:'bold', fontSize:8},
                styles:{lineColor:10, lineWidth:0.02}, 
                theme:'plain',
                head: [[{  content : 'SISTEMA INTEGRADO DE GESTIÓN' ,  colSpan : 3 ,  rowSpan : 1 ,  styles : {  halign : 'center', cellWidth: 6, fillColor:[34,171,180] } }],
                [{  content : '' ,  colSpan : 1 ,  rowSpan : 2, }, {content:'Proceso: Prestación de servicio de calibración', styles:{ halign:'center'}}, 'Código: MIS-PCB-FOR-003'],
                [ {content:'Formato: Ingreso de Ítems para Calibración', styles:{ halign:'center'}}, 'Versión: 05'],
                ],
                didDrawCell: (data) => {
                  if (data.section === 'head' && data.column.index === 0 && data.row.index===1) {
                    doc.addImage(logoBiolab, 'JPEG', data.cell.x+1.2, data.cell.y+0.1, 2.5, 0.8)
                  }},
              })
              //--------> Pie de pagina <---------------//
                autoTable(doc, {
                  startY:18.5,
                  //showHead:'everyPage',
                  footStyles:{ fillColor:[255,255,255], textColor:[0,0,0]},
                  styles:{lineColor:10, lineWidth:0.02, fontSize:8}, 
                  foot:[['Elaboro: Director de Laboratorio ','Revisó: Director de Calidad', 'Aprobó: Gerente'],],
                })
                        // Footer
                var str = "Página " + doc.internal.getNumberOfPages()
                doc.setFontSize(10);
                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                doc.setFontSize(8);
                console.log(str)
                paginas=doc.internal.getNumberOfPages()
                doc.text(str,data.settings.margin.right, 28.5);
            }
          }
          })
        doc.save(`Ingreso I-${infoActa.numeroOrden}-${infoActa.consecutivo}`) 
  }
}