import React, { useState } from 'react'
import { Observacioneq } from '../Equipo/Observacioneq';
import { Modales } from '../Modales'
import TitleEq from './TitleEq';
import { ViewImage } from './ViewImage';
import { Modalcarga } from '../Modales/Modalcarga';
import Spinner from '../commons/Spinner';
import { ActivarEq, FinalizarEq, NoFinalizarEq } from '../EqInOt/FinalizarEq';
import { useAuth } from '../../hooks/useAuth';
import { Editareq } from '../Equipo/Editareq';
import { Canceleq } from '../Equipo/Canceleq';

export const ListaequiposIn= ({Eqselect, ots,setRefrest, refrest, contadorMisEq})=> {

    let userData  = useAuth();
    let result = userData.userData;
  
    const [equipoinfo, setEquipoinfo] = useState({});
    const[estadomodal, setEstadomodal] = useState(false);
    const[modalcharge, SetModalcharge]=useState(false)
    const[equiposOtIni, setEquiposOtIni]=useState(false)
    const[tarea, setTarea]=useState()
    

    const observacion=(eq)=>{
      setEquipoinfo(eq)
      setEstadomodal(true)
      setTarea("Observacion")

      }
      const viewImg=(eq)=>{
        setEquipoinfo(eq)
        setTarea('Imagenes')
        setEstadomodal(true)
      }

      const AccesoriosView=(eq)=>{
        setEquipoinfo(eq)
        setTarea('Accesorios')
        setEstadomodal(true)
      }

      const Activar=async(eq)=>{
        const equipoActualizado = await ActivarEq(eq._id,ots.numeroOrden,ots.visitas,SetModalcharge,result,ots,eq)
        if(equipoActualizado) setRefrest(!refrest) //ActualizarEqOtIni(setEquiposOtIni,equipoActualizado)

      }
      const NoFinalizar=async(eq)=>{
        await NoFinalizarEq(eq._id,SetModalcharge,"finalizar")
        setRefrest(!refrest)
      }
      const Finalizar=async(eq)=>{
        await FinalizarEq(eq._id,ots.numeroOrden,ots.visitas,SetModalcharge,result )
        setRefrest(!refrest)
      }
      const Editequipo=(eq)=>{
        setEquipoinfo(eq)
        setEstadomodal(true)
        setTarea("Editar")
      }
      const Cancelareq=(eq)=>{
        setEquipoinfo(eq)
        setEstadomodal(true)
        setTarea("Cancelar")
      }
  return (
    <div>
      <Modalcarga
        estadoIn={modalcharge} 
        cambiarestadoIn={SetModalcharge}
        >
          <Spinner />
      </Modalcarga>
        <div >
            <Modales
            estado={estadomodal} 
            cambiarestado={setEstadomodal}
            titulo={tarea}
            >
               {tarea==='Acesorios'? <Observacioneq cambiarestado={setEstadomodal} infoobservacion={equipoinfo?.accesorios}/> :null}
               {tarea==='Imagenes'? <ViewImage cambiarestado={setEstadomodal} eqinfo={equipoinfo}/> :null}
               {tarea === 'Editar'? (<Editareq infoOt={ots} refrescar={setRefrest} refresh={refrest} infoequipo={equipoinfo} cambiarestado={setEstadomodal} setEquiposOtIni={setEquiposOtIni}/>):null}
               {tarea === 'Cancelar'? (<Canceleq infoOt={ots} cambiarestado={setEstadomodal} iditem={equipoinfo._id} setEquiposOtIni={setEquiposOtIni} setRefresh={setRefrest} refresh={refrest} />):null}
               {tarea === 'Observacion'? (<Observacioneq cambiarestado={setEstadomodal} infoobservacion={equipoinfo?.observacion?  equipoinfo?.observacion +" -- "+ (equipoinfo?.equipo?.observacion || '' ): equipoinfo?.equipo.observacion  } />):null}
               
            </Modales>

            {/*<TitleEq/>*/}
            <table className="table table-striped table-hover">
              <thead className="table-info">
                <tr>
                  <th>Nombre</th>
                  <th>Marca</th>
                  <th>Modelo</th>
                  <th>Serie</th>
                  <th>Inventario</th>
                  <th>Ubicación</th>
                  <th>Puntos</th>
                  <th>Consecutivo</th>
                  <th>Estado</th>
                  <th>Accesorios</th>
                  <th>Obs.</th>
                  <th>Fotos</th>
                  <th>{contadorMisEq}</th>
                  <th><i className="bi bi-pencil-square font_sz " ></i></th>
                  <th><i className="bi bi-x-circle font_sz "></i></th>
                </tr>
              </thead>
              <tbody>
                {Eqselect?.map((eq) => (
                  <tr key={eq._id}>
                    <td style={{ maxWidth: "150px", wordWrap: "break-word", whiteSpace: "normal" }}>
                      {eq?.equipo?.equipo.item?.nombre}
                    </td>
                    <td>{eq?.equipo?.equipo.marca}</td>
                    <td>{eq?.equipo?.equipo.modelo}</td>
                    <td>{eq?.equipo?.equipo.serie}</td>
                    <td style={{ maxWidth: "70px", wordWrap: "break-word", whiteSpace: "normal" }}>{eq?.equipo?.equipo.inventario}</td>
                    <td style={{ maxWidth: "100px", wordWrap: "break-word", whiteSpace: "normal" }}>{eq?.equipo?.equipo.ubicacion}</td>
                    <td style={{ maxWidth: "80px", wordWrap: "break-word", whiteSpace: "normal" }}>
                      {eq?.equipo?.equipo.puntos}</td>
                    <td>{eq?.equipo?.consecutivo}</td>
                    <td>{eq?.equipo?.estado}</td>
                    <td style={{ maxWidth: "60px"}}><i 
                      className="bi bi-card-list cursor-p mx-2"
                      title="Accesorios"
                      onClick={() => AccesoriosView(eq)}
                    ></i></td>
                    <td>
                      {(eq.equipo.observacion || eq.observacion) && (
                        <i className="bi bi-eye-fill cursor-p font_sz"
                        title="Observación"
                        onClick={() => observacion(eq)}></i>
                      )}
                    </td>
                    <td>
                      {eq.fotos.length > 0 && (
                        <i className="bi bi-images cursor-p" onClick={() => viewImg(eq)}></i>
                      )}
                    </td>
                   
                    {eq?.equipo?.estado==='Cancelado'?(<td><i className="bi bi-x-square-fill"></i></td>)
                    :eq?.equipo?.estado==='Procesado'?
                    <td>
                      <i className="bi bi-check-square-fill cursor-p font_sz"  onClick={()=>!eq?.equipo?.fechaCalibracion && NoFinalizar(eq.equipo)}>
                      </i>
                    </td>:
                    eq?.equipo?.estado==='Pendiente'||eq?.equipo?.estado==='Aplazado'?
                    <td>
                      <i class="bi bi-pause-circle-fill cursor-p font_sz" onClick={()=>!eq?.equipo?.fechaCalibracion && Activar(eq.equipo)}>
                      </i>
                    </td>:
                    <td className={eq?.equipo?.fechaCalibracion? 'bg-success text-white' : ''}>
                      <i className="bi bi-check-square cursor-p font_sz" onClick={()=>!eq?.equipo?.fechaCalibracion && Finalizar(eq.equipo)}>
                      </i>
                    </td>}
                    <td className={eq?.equipo?.fechaCalibracion? 'bg-success text-white' : ''}>
                      <i className= {eq?.equipo?.estado === 'Cancelado'?'disabled_row':"bi bi-pencil-square cursor-p font_sz"} onClick={()=>!eq?.equipo?.fechaCalibracion && Editequipo(eq.equipo)}>
                      </i>
                    </td>
                    <td className={eq?.equipo?.fechaCalibracion? 'bg-success text-white' : ''}>
                      <i className={eq?.equipo?.estado === 'Cancelado'?'disabled_row':"bi bi-x-circle cursor-p font_sz"} onClick={()=>!eq?.equipo?.fechaCalibracion && Cancelareq(eq.equipo)}>
                      </i>
                    </td>
                    
                    </tr>
                ))}
              </tbody>
            </table>
        </div>
    </div>
  )
}
