import React, { useEffect, useState } from 'react'
import { MultiSelect } from 'react-multi-select-component'
import { filterData, getDataList } from '../../api/common'

let remapa=[];
export const SelectMultPatron = ({handledata, collection, name, field, baseFilter, placeholder, dataDefault, excludeIds}) => {
 
    const [options, setOptions]=useState([]);
    const [selected, setSelected]=useState(remapa)

    useEffect(()=>{
        async function consult(){
            const result = await filterData(`${collection}`,
              [
                {
                  "$match": {
                    "tipoEquipo": "Patrón"
                  }
                }
              ]
            );
            const opt=result.data.data.map((item)=>({
                value:item._id,
                label:collection==='Usuarios'?item[field]+" "+item['apellido']:item[field]
            }));
            // 🔥 Filtrar opciones excluyendo los valores en `excludedIds`
            const filteredOptions = opt.filter(option => !excludeIds.includes(option.value));
            remapa = opt.filter(option => dataDefault?.includes(option.value));
            setSelected(remapa)
            setOptions(filteredOptions)
        }
        consult()
    },[excludeIds])

    const mulsele=(e)=>{
      setSelected(e)
      let arraydata=[]
      for  (let i of e){
          arraydata.push(i.value)
          }
          console.log(arraydata)
      if(arraydata.length>0){
      let event = {
        target: {
          name: name,
          value: arraydata,
        },
      };
     // //console.log(event)
      handledata(event);
    }else{
      let event = {
        target: {
          name: name,
          value: null,
        },
      };
     // //console.log(event)
      handledata(event);
    }
  }
  return (
    <div>
        <MultiSelect
            placeholder={placeholder}
            options={options}
            value={selected}
            onChange={mulsele}
            labelledBy="Select"
      />
    </div>
  )
}



