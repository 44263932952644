import React, { useEffect, useState } from 'react'
import { getDataList } from '../../api/common';

export const PatronesItem = ({ item }) => {
    const [patronesUtilizados, setPatrones] = useState([]); // Inicializar como un array vacío

    console.log(item)
    useEffect(() => {
        const consult = async () => {
            try {
                const result = await getDataList('Patrones', {
                    tipoEquipo: 'Patrón'
                });
                const patrones=result.data.data
                //setPatrones(result.data.data);  // Guardar en el estado

                // Transformamos los IDs en objetos completos
                const patronesConInfo = item?.patronesUtilizados?.map(subArray =>
                    subArray ? subArray.map(id => patrones.find(p => p._id === id)) : null
                );

                setPatrones(patronesConInfo)
                console.log(patronesConInfo)
            } catch (error) {
                console.error('Error al obtener los patrones:', error);
            }
        };
        consult();
    }, []);

    return (
        <table className="table table-striped table-bordered">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Ítem</th>
                    <th>Marca</th>
                    <th>Modelo</th>
                    <th>Estado</th>
                    <th>Foto</th>
                </tr>
            </thead>
            <tbody>
                {patronesUtilizados?.map((grupo, index) => (
                    grupo ? grupo.map((patron, idx) => (
                        <tr key={`${index}-${idx}`}>
                            <td>{index + 1}.{idx + 1}</td>
                            <td>{patron.item}</td>
                            <td>{patron.marca}</td>
                            <td>{patron.modelo}</td>
                            <td>{patron.estado}</td>
                            <td>
                                <img src={patron.foto} alt={patron.item} style={{ width: '50px', height: '50px' }} />
                            </td>
                        </tr>
                    )) : <tr key={index}><td colSpan="6"> </td></tr>
                ))}
            </tbody>
        </table>
    );
};

