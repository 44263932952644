import { useEffect, useRef, useState } from "react";
import { actualizarDatos, crearDatos, getDataObtenerUno } from "../../api/common";
import { useAuth } from "../../hooks/useAuth";
import { Modalfirma } from "./Modalfirma";
import SignaturePad from "react-signature-canvas";
import { Modalcarga } from "../Modales/Modalcarga";
import Spinner from "../commons/Spinner";
import { useForm } from "../../hooks/useForm";
import { signDocument } from "../../api/sign";
import moment from "moment";
import { Alertassw } from "../Alertas/Alertassw";



const Sign= ({ot,singWindow,setSignWindow,type,onStatus = (status)=>status, finalizarOt})=>{
    
  console.log(ot)

  const [modalcharge, SetModalcharge] = useState(false);
    const [typeSign, setTypeSign] = useState(type);
    const [signDb, setSignDB] =  useState(null)
    const { userData } = useAuth();
     const sigCanvas = useRef({});
    const [sign, setSign] = useState(null);
    const [values, handleInputChange] = useForm({
      user: "",
    });
    
  useEffect(() => {

    async function getSign() {
//console.log("======GETTING SIGN========")
 console.log(`Type Sign ${typeSign}`)
      const requestId = (typeSign === "recibe" || typeSign === "ambos")? ot?.cliente?._id:
                        (typeSign === "entrega")? userData._id:null;

      const signDb = await getDataObtenerUno("sign", {
        user: requestId,
      });
      console.log(signDb);
      setSignDB(signDb);

      if (signDb?.data?.data && sigCanvas?.current?.toDataURL()) {
        limpiar();
        setSign(signDb.data.data._id);
        if(typeSign === "entrega") sigCanvas.current.fromDataURL(signDb.data.data.sign);
      }
    }
    getSign();
  }, [singWindow,typeSign]);


  const signDataBase = async () => {
    let db;
    SetModalcharge(true);
      db = await crearDatos("sign", {
        user: ot.cliente._id ,
        sign: sigCanvas.current.toDataURL(),
      });
    return db;
  };

  const limpiar = () => {
    sigCanvas.current.clear();
  };

  const signData = async()=>{
    SetModalcharge(true);
    try{
    const signCreated = await signDataBase();
   ot.recibido={
      firma:signCreated.data.data._id,
      persona:values.user, 
      cargo:"Cliente", 
      fecha: new Date(), 
    }
    SetModalcharge(false);
    finalizarOt();
    onStatus('entregado');

    /*const signedDocument = await signDocument(ot,{
      firma:signCreated.data.data._id, 
      persona:values.user, 
      cargo:"Cliente", 
      fecha: moment().subtract(5, "hours").format(), 
      type:"recibido"
    })

    console.log(signedDocument)
    
    if(signedDocument.status=== 200) {
      limpiar();
      const link = document.createElement('a') 
      link.href = `data:application/pdf;base64,${btoa(signedDocument.data)}`
      link.download = 'document.pdf'
      link.click();
      limpiar();
      SetModalcharge(false);
      setSignWindow(false);
      onStatus('entregado');
      Alertassw("success", "OT Firmada Exitosamente");
    }
    else{
      Alertassw('error','Error guardando firma')
    }*/
    }
    catch(error){
      SetModalcharge(false);
      Alertassw("error", "Error Guardando Firma");
        console.log(error)
    }
  } 

    return (
        <>
 <Modalfirma estadofirma={singWindow}>
        <>
          <SignaturePad
            ref={sigCanvas}
            canvasProps={{
              className: "firmas",
            }}
          />
          <div>
            <div className="limpiar-name">
                <input
                  type={"text"}
                  placeholder="Recibe a satisfaccón"
                  className="w-100"
                  onChange={handleInputChange}
                  name="user"
                  required
                ></input>
              <button onClick={limpiar} className="btn-limpiar">
                Limpiar
              </button>
            </div>
            <div className="botones">
              <button
                className="btn-btn-tertiary"
                onClick={() => setSignWindow(false)}
              >
                Cancelar
              </button>
              <button
                className="btn-btn-secundary"
                onClick={() => signData()}
              >
                Firmar
              </button>
            </div>
          </div>
        </>
      </Modalfirma>
      <Modalcarga estadoIn={modalcharge} cambiarestadoIn={SetModalcharge}>
        <Spinner />
      </Modalcarga>
        </>
    );
        



}

export default Sign;