import React, { useEffect, useState } from 'react'
import { MultiSelect } from 'react-multi-select-component'
import { filterData, getDataList } from '../../api/common'

let remapa=[];
export const PatronesAuxilires = ({handledata, collection, name, field, baseFilter, placeholder, dataDefault}) => {
 
    if(dataDefault?.length>0){
        remapa= dataDefault.map(e=>({"label": e.item,"value": e._id}))
      }else{
        remapa=[]
      }
      console.log(remapa)

    const [options, setOptions]=useState([]);
    const [selected, setSelected]=useState(remapa)


    useEffect(()=>{
        async function consult(){
            const result = await filterData(`${collection}`,[
                {
                  "$match": {
                    "tipoEquipo": "Auxiliar"
                  }
                }
              ]);

            console.log(result.data.data)
            const opt=result.data.data.map((item)=>({
                value:item._id,
                label:item[field]
            }));
            setOptions(opt)
        }
        consult()
    },[])

    const mulsele=(e)=>{
      setSelected(e)
      let arraydata=[]
      for  (let i of e){
          arraydata.push(i.value)
          }
          console.log(arraydata)
      if(arraydata.length>0){
      let event = {
        target: {
          name: name,
          value: arraydata,
        },
      };
     // //console.log(event)
      handledata(event);
    }else{
      let event = {
        target: {
          name: name,
          value: null,
        },
      };
     // //console.log(event)
      handledata(event);
    }
  }
  return (
    <div>
        <MultiSelect
            placeholder={placeholder}
            options={options}
            value={selected}
            onChange={mulsele}
            labelledBy="Select"
      />
    </div>
  )
}