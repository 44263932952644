import React, { useState } from 'react'
import { aprobarEquipo } from '../../api/common';

//crear array de id de items seleccionados con checbox y eliminarlos cuando se deselecciona, el checbox estara en otro componente diferente, se emplea react para esta actividad ?

let selectIems=[]

export const  AprobarSelect=(eq)=> {
        //const [selectedItems, setSelectedItems] = useState([]);
        // Verificar si el elemento ya está en la lista
        if(eq){
            if (selectIems.some(objeto => objeto._id === eq._id)) {
            // Si está, eliminarlo
            selectIems= selectIems.filter((item) => item._id !== eq._id);
            } else {
            // Si no está, agregarlo
            selectIems.push(eq);
          }
        }
  return (selectIems)
}

export const  AprobarAll=(eqs, selector)=> {
  return (eqs.filter(equipo=> equipo.estado==='Finalizado'))
}



export const  leerVar=()=> {
    return(selectIems)
}

export const AprobarSeleccion=async(eqs,usuario,ots,visita,SetModalcharge,setRefresh,refresh)=>{

    try {
        SetModalcharge(true)
        let verificar=AprobarAll(eqs)
        const data = verificar.map((item)=>({
            ...item,
            _id:item._id,
            estado:'Aprobado',
            fechaAprobado: new Date(),
            responsableChange:usuario,
            responsableAprobado:usuario,
            visita:visita,
            numeroOrden:ots,
            change:true
        }));

        const  response = await aprobarEquipo('EqInOt',data)
        SetModalcharge(false)
        setRefresh(!refresh)     
    } catch (error) {
        console.log(error)   
    }
    
}