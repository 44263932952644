
import autoTable from 'jspdf-autotable';
import { jsPDF } from "jspdf";
import moment from 'moment'
import { getDataObtenerUno } from '../../api/common';
import 'moment/locale/es';
import { logoBiolab } from '../../helpers/imagen/Imagen';
moment().locale('es')



export const PdfActa=(infoActa)=> {
  var contador=0;
  var paginas=0;
  let numeroOrden=infoActa.numeroOrden || infoActa.orden.numeroOrden

  console.log(numeroOrden)
  console.log(infoActa)
  const equipos=infoActa.Equipos
  //'P', 'cm', 'A4'
  const doc = new jsPDF({
    unit:'cm',
    orientation:'p',
    format:'A4',
    encryption:{
      ownerPassword:"Biolab26#.",
      userPermissions:["print","copy"]
    }
  })
  //console.log(infoActa)
  //console.log(equipos)
  async function getS() {
    const signDb = await getDataObtenerUno("sign", { user: infoActa.autor._id });
    if(signDb.status===200){
      crearpdf(signDb.data.data.sign)
    }
  }
  getS();

    const estados=({
        'Equipo': 'Ítems en calibración',
        'Certificado':'certificados de calibración',
        'Certificado y Equipo':'Equipos y Certificados de calibración'
    })

const crearpdf = (firma)=>{
        doc.setFontSize(10)
          autoTable(doc,{
            startY:3,
            showHead:'everyPage',
            headStyles:{fontStyle:'bold', fontSize:8, halign:'center'},
            theme:'plain', 
            head:[
                [{content:'',styles:{cellWidth:14}},{content:'Consecutivo:', styles:{fillColor:[34,171,180], lineColor:10, lineWidth:0.02}},{content:'A-'+numeroOrden+'-'+infoActa.consecutivo , styles:{lineColor:10, lineWidth:0.02}}]
            ]
          })
        doc.text('Cereté / '+moment(infoActa.createdAt).format("YYYY-MM-DD"),1.5,3.5)
        doc.text('Señores:',1.5,4.5)
        doc.text(equipos[0]?.equipo?.equipo.cliente?.nombre + " / "+infoActa?.sede,1.5,5)
        doc.text(`${equipos[0]?.equipo?.equipo.cliente?.ciudad} - ${equipos[0]?.equipo.equipo?.cliente?.departamento}`,1.5,5.5)
        doc.text(`ASUNTO: Entrega formal de ${estados[infoActa.entrega]}` ,1.5,6.5)
        doc.text('Cordial saludo',1.5,7.5)
        doc.text(`Mediante la presente hacemos entrega ${infoActa.tipo} de ${estados[infoActa.entrega]}.`,1.5,8.5)
        doc.text('Lo anterior se relaciona a continuación:',1.5,9.5)
        
//----------> contenido de equipos <------------------        
        autoTable(doc,{
            startY:10,
            theme:'plain',
            headStyles:{fontStyle:'bold', fillColor:[34,171,180], textColor:[0,0,0]},
            showHead:'everyPage',
            showFoot:'lastPage',
            margin:{top:3, bottom:2.7},
            styles:{lineColor:10, lineWidth:0.01, fontSize:8, halign:'center'}, 
            head: [
                [{ content : 'RELACIÓN DE EQUIPOS Y/O CERTIFICADOS' ,  colSpan : 5}],
                ['No.','ÍTEM/EQUIPO', 'SERIAL','No. STIKER/CERTIFICADO', 'ENTREGA',],
              ], 
            body:equipos.map((equipo,index)=>[
                index+1,equipo?.equipo?.equipo?.item?.nombre, equipo.equipo.equipo.serie, equipo.equipo.estado.toLowerCase() ==='cancelado'? 'No Aplica': equipo.equipo.consecutivo , equipo.tipoEntrega
            ]),
            foot:[[{content:'Observación', colSpan:5, styles:{halign:'center', fillColor:[34,171,180], textColor:[0,0,0]}}],
            [{content:`${infoActa?.observacion}`, colSpan:5, styles:{fillColor:[255,255,255], halign:'left',fontStyle:'normal'}}]
            ],

            didDrawPage: function (data) {
              //Encabezado
              autoTable(doc, {
                //theme:'plain',
                startY:0.5,
                showHead:'everyPage',
                headStyles:{fontStyle:'bold', fontSize:6},
                styles:{lineColor:10, lineWidth:0.02}, 
                theme:'plain',
                head: [[{  content : 'SISTEMA INTEGRADO DE GESTIÓN' ,  colSpan : 3 ,  rowSpan : 1 ,  styles : {  halign : 'center', cellWidth: 6, fillColor:[34,171,180] } }],
                      [{  content : '' ,  colSpan : 1 ,  rowSpan : 2, }, {content:'Proceso: Gestión Comercial', styles:{ halign:'center'}}, 'Código: MIS-GCO-FOR-002'],
                      [ {content:'Formato: Acta de entregas de items y/o Certificados', styles:{ halign:'center'}}, 'Versión: 06'],
                      ],
                didDrawCell: (data) => {
                  if (data.section === 'head' && data.column.index === 0 && data.row.index===1) {
                    var base64Img = logoBiolab
                    doc.addImage(base64Img, 'JPEG', data.cell.x+1.2, data.cell.y+0.1, 2.5, 0.8)
                  }},
              })
              //--------> Pie de pagina <---------------//
                autoTable(doc, {
                  startY:27.5,
                  //showHead:'everyPage',
                  footStyles:{ fillColor:[255,255,255], textColor:[0,0,0]},
                  styles:{lineColor:10, lineWidth:0.02, fontSize:8}, 
                  foot:[['Elaboró: Director de calidad ','Revisó: Director de Laboratorio', 'Aprobó: Gerente'],],
                })
                        // Footer
                var str = "Página " + doc.internal.getNumberOfPages()
                doc.setFontSize(10);
                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                doc.setFontSize(8);
                console.log(str)
                paginas=doc.internal.getNumberOfPages()
                console.log(paginas)
                doc.text(str,data.settings.margin.right,28.5);
            }
        })
        //-----------> Fin Contenido <---------------//

        autoTable(doc,{
            styles:{textColor:[0,0,0]},
            theme:'plain',
            body:[
                ['De acuerdo a la relación anterior se recibe a satisfacción los ítems y/o certificados.']
            ],
        })

        //--------> Espacio de firmas <----------------//

        autoTable(doc, {
            bodyStyles:{fillColor:[255,255,255], valign:'bottom', fontSize:8},
            theme:'plain',
            margin:{top:2, bottom:3},
            pageBreak:'avoid',
            body: [
              [{content:'Entrega:', styles:{cellWidth:3,}},{content:'', colSpan:2, styles:{cellWidth:6, minCellHeight:2.5}},'Recibe:',''],
              ['Nombre:',{content:infoActa.autor.nombre +' '+infoActa.autor.apellido, colSpan:2,},'Nombre:',''],
              ['Fecha y Hora:',moment(infoActa.createdAt).format('lll'),'','Fecha y Hora:',''],
          ],
          didDrawCell:(data)=>{
            if(data.section==='body' && data.column.index === 1 && data.row.index === 1){
              var firmabase64Img=firma
              doc.addImage(firmabase64Img,'png',data.cell.x+0.6, data.cell.y-1.8,3, 2)
            }
            if(contador===0 && paginas<doc.internal.getNumberOfPages()){
              contador++;
              //Encabezado
              autoTable(doc, {
                //theme:'plain',
                startY:0.5,
                showHead:'everyPage',
                headStyles:{fontStyle:'bold', fontSize:6},
                styles:{lineColor:10, lineWidth:0.02}, 
                theme:'plain',
                head: [[{  content : 'SISTEMA INTEGRADO DE GESTIÓN' ,  colSpan : 3 ,  rowSpan : 1 ,  styles : {  halign : 'center', cellWidth: 6, fillColor:[34,171,180] } }],
                      [{  content : '' ,  colSpan : 1 ,  rowSpan : 2, }, {content:'Proceso: Gestión Comercial', styles:{ halign:'center'}}, 'Código: MIS-GCO-FOR-002'],
                      [ {content:'Formato: Acta de entregas de items y/o Certificados', styles:{ halign:'center'}}, 'Versión: 06'],
                      ],
                didDrawCell: (data) => {
                  if (data.section === 'head' && data.column.index === 0 && data.row.index===1) {
                    doc.addImage(logoBiolab, 'JPEG', data.cell.x+1.2, data.cell.y+0.1, 2.5, 0.8)
                  }},
              })
              //--------> Pie de pagina <---------------//
                autoTable(doc, {
                  startY:27.5,
                  //showHead:'everyPage',
                  footStyles:{ fillColor:[255,255,255], textColor:[0,0,0]},
                  styles:{lineColor:10, lineWidth:0.02, fontSize:8}, 
                  foot:[['Elaboro: Director de calidad ','Revisó: Director de Laboratorio', 'Aprobó: Gerente'],],
                })
                        // Footer
                var str = "Página " + doc.internal.getNumberOfPages()
                doc.setFontSize(10);
                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                doc.setFontSize(8);
                console.log(str)
                paginas=doc.internal.getNumberOfPages()
                console.log(paginas)
                doc.text(str,data.settings.margin.right, 28.5);
            }
          }
          })
          doc.save(`Acta A-${numeroOrden}-${infoActa.consecutivo}`)
          //window.open(doc.output('bloburl'),'_blank')
  }
}
