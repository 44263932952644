import React, { useEffect, useState } from 'react'
import { actualizarDatos } from '../../api/common'
import { Alertassw } from '../Alertas/Alertassw'
import SelectMult from '../SettingNot/SelectMult'
import { SelectMultPatron } from './SelectMultPatron'
import SelectMg from './SelectMg'
import { PatronesAuxilires } from './PatronesAuxiliares'


 export const EditarItem=({itemData, cambiarestado , setRefresh, refresh, setSpinnerMod})=> {
    const [patronesUtilizados, setPatronesUtilizados] = useState(() => {
        return itemData?.patronesUtilizados?.length > 0 ? itemData.patronesUtilizados : [[]];
      });

      console.log(itemData)
    const addSubArray = () => {
        setPatronesUtilizados([...patronesUtilizados, []]); // Agrega un nuevo array vacío
      };
      
    const[dataAct, setDataAct]=useState({
        _id:itemData._id
    })

    console.log(dataAct)

    const saveChange=(e)=>{
        e.preventDefault()
        try {
            setSpinnerMod(false)
            async function guardarCambios(){
                const result=  await actualizarDatos('Items',dataAct)
                console.log(result)
                Alertassw('charge','Cambio Guardado correctamente')
                setRefresh(!refresh)
                setSpinnerMod(false)
                cambiarestado(false)
            }
            guardarCambios()
        } catch (error) {
          Alertassw('error','Ha ocurrido un error','Intenta nuevamente, si el error persiste reporta el fallo a soporte tecnico') 
          cambiarestado(false);
          setRefresh(!refresh)
          setSpinnerMod(false)
        } 
    }
    const handlePatrones=(index,selectedValues)=>{
        console.log(selectedValues)
        const updatePatrones=[...patronesUtilizados];
        updatePatrones[index]=selectedValues.target.value;
        setPatronesUtilizados(updatePatrones);
        setDataAct({
            ...dataAct,
            patronesUtilizados: updatePatrones
        })
    }
    const getSelectedIds=(excludeIndex)=>{
        return patronesUtilizados.filter((__, index)=> index!==excludeIndex).flat(2)
    }
    
    const handleData=(event)=>{
     console.log(event)
     setDataAct({
        ...dataAct,
        [event.target.name]:event.target.value
    })
    }

    useEffect(() => {
        // Verifica que Bootstrap esté disponible antes de inicializar tooltips
        if (window.bootstrap && window.bootstrap.Tooltip) {
          const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
          tooltipTriggerList.forEach(tooltipEl => {
            new window.bootstrap.Tooltip(tooltipEl);
          });
        }
      }, []);
  return (
<form onSubmit={saveChange}>
    <div className='col-1MD'>
                <label className='mg-5-all' htmlFor='nombre'>Nombre del Ítem</label>
                <input  className='mg-5-all' name='nombre' onChange={handleData} type='string' defaultValue={itemData.nombre}></input>
                <label className='mg-5-all' htmlFor='magnitud'>Magnitud</label>
                <SelectMg
                handleData={handleData}
                dataDefault={itemData.magnitud}
                />
                <label className='mg-5-all' htmlFor='tiempo'>Tiempo/min </label>
                <input  className='mg-5-all' name='tiempo' onChange={handleData} type='number' defaultValue={itemData.tiempo}></input>
                <label className='mg-5-all'  htmlFor='cantidad' >Cantidad/día</label>
                <input  className='mg-5-all' name='cantidad' onChange={handleData} type='number'defaultValue={itemData.cantidad}></input>
                <label className='mg-5-all'  htmlFor='grupo'>Grupo</label>
                <input  className='mg-5-all' name='grupo' onChange={handleData} type='number'defaultValue={itemData.grupo}></input>
                <label className='mg-5-all'  htmlFor='userAutorizados'>Usuarios Autorizados</label>
            <div class="mb-3">
                <SelectMult
                dataDefault={itemData?.userAutorizados}
                handledata={handleData}
                name='userAutorizados'
                collection={"Usuarios"}
                field={"nombre"}
                baseFilter={{ 
                    "role.nombre": "~Cliente",
                    "type":"foreign",
                    "populate":[] }}
                />
            </div>
                {/*<UsuariosSelect name="userAutorizados"  handledata={handleData}/>*/}
                {/*<label className='mg-5-all'  htmlFor='patrones'>Patrones Autorizados</label>*/}
                <div className="d-flex align-items-center">
                <label className="mg-5-all" htmlFor="patrones"> <strong>Patrones Autorizados</strong></label>
                <i
                    className="ms-2 bi bi-info-circle text-primary"
                    style={{ cursor: "pointer" }}
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    title="Recuerda: dentro de cada grupo de patrones, al menos un equipo es obligatorio para continuar con el proceso."
                ></i>
                </div>
                {patronesUtilizados.map((subArray, index) => {
                    const selectIds=getSelectedIds(index);
                    console.log("subarray")
                    return(
                    <div key={index} className="mb-3">
                    <label className='mg-5-all'  htmlFor='patrones'>Grupo {index+1}</label>
                    <SelectMultPatron
                        dataDefault={subArray} // Valores actuales del subarray
                        handledata={(selectedValues) => handlePatrones(index, selectedValues)}
                        name={`patronesUtilizados_${index}`}
                        collection={"Patrones"}
                        field={"item"}
                        excludeIds={selectIds}
                    />
                    </div>
                )})}
                <label onClick={addSubArray} className="mt-2 btn-btn-tertiary">+ Agregar otro grupo</label>

                <label className='mg-5-all'  htmlFor='patronesAuxiliares'>Patrones Auxiliares</label>
                    <PatronesAuxilires
                        dataDefault={itemData?.patronesAuxiliares} // Valores actuales del subarray
                        handledata={handleData}
                        name={`patronesAuxiliares`}
                        collection={"Patrones"}
                        field={"item"}
                        baseFilter={{
                            "tipoEquipo": "Auxiliar" }}
                    />
                
                
                <label className='mg-5-all'  htmlFor='descripcion'>Descripción</label>
                <textarea  className='mg-5-all' name='descripcion' onChange={handleData} type='string' defaultValue={itemData.descripcion}></textarea>
                <button className='btn-btn-primary w-100 mg-20-top' type='submit' >Guardar cambios</button>
        </div>
</form>

  )
}
