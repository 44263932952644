import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";
import 'reactjs-popup/dist/index.css';
import { Modalcarga } from '../Modales/Modalcarga';
import Spinner from '../commons/Spinner';
import { EqHoja } from './EqHoja';
import Sign from './Sign';
import { Alertassw } from '../Alertas/Alertassw';
import { actualizarOt, getDataObtenerUno } from '../../api/common';
import { useAuth } from "../../hooks/useAuth";
import { PdfOt } from './PdfOt';


export const  Otsonpdf =({cambiarestado,refresh,setRefresh, otFath, EqSedeFilter, obsGen, sitioSeleted})=> {
  
  const navigate = useNavigate();
  const [ estadomodalIn,setEstadomodalIn ] = useState(false)
  const [datason, setDatason]=useState({})
  const { userData } = useAuth();
  const [responsablesSeleccionados, setResponsablesSeleccionados] = useState([
    {id:userData._id,
      nombre:userData.nombre,
      apellido:userData.apellido
    }
  ]);  //Usuarios seleccionados para filtrar los equipos de la OT
  const [estadofirma, setEstadofirma]=useState(false)
  const [status, setStatus] = useState(null);
  const [statusVer, setStatusVer]=useState(false);
  const [estadoSig, setEstadoSig]=useState(false)
  const [firmaUser, setFirmaUser]=useState();
  const [responsables, setResponsables]=useState();

  const handleCheckboxChange = (responsable) => {
    setResponsablesSeleccionados((prev) => {
      // Si ya está en la lista, lo quitamos
      if (prev.some(r => r.id === responsable.id)) {
        return prev.filter(r => r.id !== responsable.id);
      } else {
        // Si no está, lo agregamos
        return [...prev, responsable];
      }
    });
  };
  const finalizarOt=()=>{
      // cambio el estado a finalizados para enviar al servidor.
      for(let i=0; i<datason.equipos.length; i++){
        if(datason.equipos[i].estado==='Procesado'){
          datason.equipos[i].estado='Finalizado'
        }
        if(datason.equipos[i].estado==='Pendiente'){
          datason.equipos[i].estado='Aplazado'
        }
      }
    async function putOt(){
      setEstadomodalIn(true)
      datason.entregado={
        firma:firmaUser._id,
        persona:userData?.nombre +" "+ userData?.apellido, 
        cargo:userData?.role?.nombre, 
        fecha: new Date(), 
      }
      const ota= await actualizarOt("Ot", datason, datason._id)
      if(ota.status===200){
        setStatus('entregado')
        //setEstadoSig(true)
        setEstadomodalIn(false)
        setRefresh(!refresh)
        Alertassw('toast','Se ha finalizado la Orden trabajo correctamente')
        setEstadofirma(false);
        const dataot=ota.data.data;
        console.log(dataot)
        PdfOt(dataot, dataot.entregado.firma, dataot.recibido.firma,false)
        Alertassw("success", "OT Firmada Exitosamente");
        cambiarestado(false)
      }
    }
    putOt()
  }

  const Firmar=()=>{
    setEstadoSig(true)
    setEstadofirma(true);
  }
  useEffect(() => {
    if (!EqSedeFilter) return; // Evita errores si EqSedeFilter es undefined

    const equiposFiltrados = EqSedeFilter.filter((eqEnd) => 
      (eqEnd.estado === "Procesado" || eqEnd.estado === "Cancelado" || eqEnd.estado === "Pendiente") &&
      eqEnd.responsable && // Verifica que eqEnd.responsable no sea undefined
      responsablesSeleccionados.some(selected => selected.id === eqEnd.responsable._id)
    );  
    const observacion =unirobs(equiposFiltrados,obsGen)

    setDatason({
      ...datason,
      equipos:equiposFiltrados,
      observacion:observacion
    })    /// Seteo los equipos a la hoja de trabajo de acuerdo a los usuarios seleccionados

  }, [responsablesSeleccionados]); // Agrega EqSedeFilter como dependencia para evitar errores
  
  useEffect(()=>{
    const equiposFiltrados = EqSedeFilter.filter((eqEnd) => 
      (eqEnd.estado === "Procesado" || eqEnd.estado === "Cancelado" || eqEnd.estado === "Pendiente") &&
      eqEnd.responsable && // Verifica que eqEnd.responsable no sea undefined
      responsablesSeleccionados.some(selected => selected.id === eqEnd.responsable._id)
    ); 

    const observacion =unirobs(equiposFiltrados,obsGen)

    const responsablesUnicos = Array.from(   //Calcula los resposanbles disponibles 
      new Map(
        otFath.equipos
          .filter(eq => eq.responsable && !eq.fechaCalibracion) // Filtra equipos con responsable y sin fechaCalibracion
          .map(eq => [
            eq.responsable._id, 
            {
              id: eq.responsable._id,
              nombre: eq.responsable.nombre,
              apellido: eq.responsable.apellido.charAt(0) // Solo la primera letra del apellido
            }
          ])
      ).values()
    );
    setResponsables(responsablesUnicos)   //Carga el array de los responsables 
    setDatason({
      _id:otFath._id,
      idFath:otFath._id,
      numeroOrden :otFath.numeroOrden,
      sede:otFath.sede,
      visitas:otFath.visitas,
      observacion:'',
      equipos:equiposFiltrados,
      observacion:observacion,
      cliente:otFath.cliente,
      sitioCal:sitioSeleted||'Sitio'

    })

async function consultarSing(){   //Consulta firma
    const signDb = await getDataObtenerUno("sign", {
      user: userData._id,
    });
    const Otfath = await getDataObtenerUno("Ot", {
      _id: otFath._id,
    });
    if(Otfath.status==200){
      datason.cliente=Otfath.data.data.cliente
    }
    setFirmaUser(
      signDb.data.data
    )
    if(signDb.status==200){
      setStatusVer(true);
      datason.entregado={
        firma:signDb.data.data._id,
        persona:userData.nombre+" "+userData.apellido,
        cargo:userData.role.nombre,
        fecha:new(Date) 
      }
    }
  }
  consultarSing()
  },[])

  if(status=== "fin entrega"){
    cambiarestado(false)
    Alertassw('ok', 'Has creado un hoja de trabajo','Descarga en proceso' )
    navigate(`/Ot`);
  }

  return (
    <div >
      <Modalcarga
        estadoIn={estadomodalIn} 
        cambiarestadoIn={setEstadomodalIn}
        >
          <Spinner />
      </Modalcarga>
      <div className="container w-100">
        <div className="row align-items-center p-3 border">
          {/* Botones alineados a la izquierda */}
          <div className="col-auto">
            {status === "entregado" ? null : (
              <button onClick={Firmar} className="btn btn-primary me-2">Solicitar firma</button>
            )}
            {statusVer === false ? null : (
              <button onClick={() => PdfOt(datason, firmaUser, "", true)} className="btn btn-secondary">Ver hoja</button>
            )}
          </div>

          {/* Checkboxes alineados a la derecha */}
          <div className="col text-end">
            {responsables?.map(responsable => {
               // Verificar si el responsable coincide con userData por ID o identificación
              const esUsuarioActual = responsable.id === userData._id || responsable.identificacion === userData.identificacion;
              return(
              <div key={responsable.id} className="form-check d-inline-block me-3">
                <input 
                  type="checkbox" 
                  id={responsable.id} 
                  className="form-check-input"
                  onChange={() => handleCheckboxChange(responsable)}
                  checked= {esUsuarioActual? esUsuarioActual:null} // Si es el usuario actual, marcarlo como seleccionado
                  disabled={esUsuarioActual} // Si es el usuario actual, deshabilitarlo
                />
                <label htmlFor={responsable.id} className="form-check-label ms-1">
                  {`${responsable.nombre} ${responsable.apellido}.`}
                </label>
              </div>
            );
            })}
          </div>
        </div>
      </div>
        <EqHoja datason={datason}/> 
       {estadoSig?
        <Sign 
        singWindow={estadofirma}  
        setSignWindow={setEstadofirma} 
        type={"ambos"} 
        ot={datason} 
        onStatus={(status)=>setStatus(status)} 
        finalizarOt={finalizarOt}
        firmaUser={firmaUser}/>:
        <></>
       }
        </div>
  )
}


export const unirobs=(filtradoFinalizar, obsGen)=>{
  var observaciones=''
  var j=1;
  if(obsGen){
    var observaciones =j+") "+obsGen+".\n"
    j++
  }
  for(var i=0; i<filtradoFinalizar.length; i++){
    if(filtradoFinalizar[i]?.observacion){
      var observaciones = observaciones +""+j +") " + filtradoFinalizar[i]?.equipo.item.nombre +" - "+
      filtradoFinalizar[i]?.consecutivo+" "+ filtradoFinalizar[i]?.observacion+ ".\n"
      j++;
    }
  }
  return observaciones
}