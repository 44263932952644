import React, { useEffect, useState } from 'react'
import Select from 'react-dropdown-select'
import { getDataList } from '../../api/common';

const SelectMg = ({dataDefault, handleData}) => {
    console.log("dataDefault: ",dataDefault)
  const [listmg, setListmg] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]); // Valor seleccionado por defecto

  const selectNewMg=(data)=>{
    console.log(data)
    handleData({target:
        {value:data[0].value,
            name:'magnitud'
        }})
    
    setSelectedValue(data)

  }
  useEffect(() => {
    async function getUsers() {
      const magnitudDB = await getDataList('magnitud');
      console.log(magnitudDB)
      const magnitudselect = magnitudDB.data.data.map((listamg)=>({
        value:listamg._id,
        label:listamg.nombre + "  ("+listamg.consecutivo+")"
    }))

    setListmg(magnitudselect)

    // 💡 Establecer el valor por defecto solo si `dataDefault` existe y coincide con la lista
    const defaultOption = magnitudselect.find((option) => option.value === dataDefault._id);
    if (defaultOption) {
    setSelectedValue([defaultOption]); // `react-dropdown-select` espera un array
    }
    }
    getUsers()
},[])

  return (
    <div>
       <Select  
       searchable='true' 
       options={listmg} 
       values={selectedValue} 
       placeholder='Seleciona una magnitud' 
       onChange={(values) => selectNewMg(values)} // Manejo del cambio
       required/>
    </div>
  )
}

export default SelectMg