import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { getDataList, postDataItem } from '../api/common'
import Select from "react-dropdown-select";
import { Alertassw } from '../components/Alertas/Alertassw';
import { paginate } from '../api/paginate';
import { Pagination } from '../components/commons/Pagination';
import Spinner from '../components/commons/Spinner';
import { ExcelItems } from '../components/Items/ExcelItems';
import { Modalcarga } from '../components/Modales/Modalcarga';
import { Modales } from '../components/Modales';
import { Observacioneq } from '../components/Equipo/Observacioneq';
import { EditarItem } from '../components/Items/EditarItem';
import { PatronesItem } from '../components/Items/PatronesItem';


export const Items =() => {
 
    const [page, setPage] = useState(1);
    const[spinnerMod, setSpinnerMod]=useState(false);
    const[modalObs, setModalObs]=useState(false);
    const[modalPatron, setModalPatron]=useState(false);
    const[modaleEdit, setModalEdit]=useState(false);
    const[itemData, setItemData]=useState();
    const[refresh, setRefresh]=useState(false)
    const[totalItems, setTotalItems]=useState()

    // constante que almacena el listado de las magnitudes guardadas
    const [listmg, setListmg] = useState([]);
    
    // filtro y me quedo con los datos de la magnitud seleccionada
    const Selectmg = (value)=>{
        if (value.length>0){
            const result = magnitudes.filter(word => word._id === value[0].value);
            setItem({
                ...item,
                magnitud:result[0]._id
            })
        }else{         
            setItem({
            ...item,
            magnitud:''
        })}
    }
    const [items, setItems]=useState([]);
     
    console.log(items)
    //constante que almacena las magnitudes 
    const [magnitudes, setMagnitudes]=useState([]);

    const [item, setItem]=useState({
        nombre:'',
        tiempo:'',
        codigo:50,
        magnitud:''
    })
    //evento para ver observacion del item
    const observacion=(it)=>{
        setItemData(it);
        setModalObs(true)
      }

      const patrones=(it)=>{
        console.log(it)
        setItemData(it);
        setModalPatron(true)
      }

      //evento para editar 
      const HandleEdit=(it)=>{
        setItemData(it);
        setModalEdit(true);
      }
    //evento para capturar la informacion de los inputs del formulario de magnitud
    const dataitem = (event) =>{
        setItem({
            ...item,
         [event.target.name]:event.target.value
        })
    }
    // para elimiar de la lista un objeto 

    const changePage = (page) => {
        setItems({ ...items, results: {} });
        setPage(page);
      };

    const del = (index)=>{
        items.splice(index,1)
        setItems([
          ...items
        ])
      }

    //Evento para almacenar las magnitudes
    const saveItem = (e)=>{
            setSpinnerMod(true)
            e.preventDefault();
        if(item.magnitud){
            async function postItem(){
                try {
                    const itemDB = await postDataItem(item);
                    Alertassw('success', 'Ítem creado correctamente')
                    setSpinnerMod(false)   
                } catch (error) {
                    Alertassw('error','Error en la creación','Verifica los campos e ingresa los valores correctos')
                    setSpinnerMod(false)
                }
            }
            postItem()
        }else{Alertassw('error','Magnitud no seleccionada' )}
        
    }
    // Funcion para traer todas las magnitudes almacenadas
    useEffect(() => {        
        async function getItem(){

            /*PAGINATED */
            const totaItem = await getDataList('Items')
            setTotalItems(totaItem.data.data)
            const paginatedItems = await paginate(
                `/Items/paginar?page=${page}&perPage=20`,
                { conditions: {}, sort: { _id: -1 } }
              );

            setItems(paginatedItems.data.data);

            const magnitudDB = await getDataList('magnitud');

            const magnitudselect = magnitudDB.data.data.map((listamg)=>({
                value:listamg._id,
                label:listamg.nombre
            }))

            setListmg(magnitudselect)
            setMagnitudes(magnitudDB.data.data);
        }
        getItem()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[page, refresh])

    const handleExport=()=>{
        ExcelItems(totalItems)

    }

  return (
    <div>
        <Modalcarga
          estadoIn={spinnerMod} 
          cambiarestadoIn={setSpinnerMod}>
          <Spinner />
       </Modalcarga>
       <Modales
            estado={modalObs}
            cambiarestado={setModalObs}
            titulo={`Descripción de ${itemData?.nombre}`}
          >
            <Observacioneq cambiarestado={setModalObs} infoobservacion={itemData?.descripcion} />
        </Modales>
        <Modales
            estado={modaleEdit}
            cambiarestado={setModalEdit}
            titulo={`Descripción de ${itemData?.nombre}`}
          >
            <EditarItem 
                itemData={itemData} 
                setData={setItemData} 
                cambiarestado={setModalEdit}  
                setRefresh={setRefresh} 
                refresh={refresh}
                setSpinnerMod={setSpinnerMod} />
        </Modales>

        <Modales
            estado={modalPatron}
            cambiarestado={setModalPatron}
            titulo={`Equipos patrones`}
          >
            <PatronesItem
                item={itemData} 
                />
        </Modales>

        <div className='cabecera'>
            <label className='titulo'>Items / Equipos</label>
        </div>
      <div >
            <form className='item-header border-ot ' onSubmit={saveItem}>
                <input type={'string'} className='datauseri pd-5px' onChange={dataitem}  name='nombre' placeholder='Item' required/>
                <input className='datauseri pd-5px' onChange={dataitem} type='number' name='tiempo' placeholder='Tiempo Calibración (min)'  required/>
                <input className='datauseri pd-5px' onChange={dataitem} type='number' name='cantidad' placeholder='Cantidad/día' required/>
                <input className='datauseri pd-5px' onChange={dataitem} type='number' name='grupo' placeholder='Grupo' />
                <textarea className='descripcion pd-5px' onChange={dataitem} type={'string'} name='descripcion' placeholder='Descripcion' required/>
                <div className='selectedmg'><Select  searchable='true' onChange={Selectmg} options={listmg}  placeholder='Seleciona una magnitud' required/></div>
                <button className='btn-btn-secundary w-100 submit' type='submit'>Crear Item</button>
            </form>
      </div>
      <hr/>
      <div className="table-responsive">
      {items.results?.length === 0 ? (
        <div>No hay ítems creados</div>
      ) : items.results?.length >= 0 ? (
        <table className="table table-striped table-hover">
          <thead className="table-info">
            <tr>
              <th>Ítems</th>
              <th title="Magnitud medida">Magnitud</th>
              <th title="Consecutivo Certificado">Consecutivo</th>
              <th title="Duración de la calibración">T. Cal</th>
              <th title="Cantidad por día">Cant/día</th>
              <th>Grupo</th>
              <th title="Usuarios Autorizados">Usuarios Auth</th>
              <th title="Patrones Autorizados">Patrones</th>
              <th>Desc.</th>
              <th>Edit.</th>
            </tr>
          </thead>
          <tbody>
            {items.results.map((it) => (
              <tr key={it._id}>
                <td>{it?.nombre}</td>
                <td>{it?.magnitud?.nombre}</td>
                <td>{it?.magnitud?.consecutivo}</td>
                <td>{it?.tiempo}</td>
                <td>{it?.cantidad}</td>
                <td>{it?.grupo}</td>
                <td style={{ maxWidth: "80px", wordWrap: "break-word", whiteSpace: "normal" }}>
                  {it?.userAutorizados?.map((user) => `${user.nombre} ${user.apellido.charAt(0)}`).join(", ")}
                </td>
                <td><i 
                      onClick={() => patrones(it)}
                      className="bi bi-card-list cursor-p mx-2"
                      title="Patrones Autorizados"
                    ></i></td>
                <td>
                  <i
                    onClick={() => observacion(it)}
                    className="bi bi-eye-fill text-primary cursor-p mx-2"
                    title="Ver descripción"
                  ></i>
                  </td>
                  <td>
                  <i
                    onClick={() => HandleEdit(it)}
                    className="bi bi-pencil-square text-warning cursor-p"
                    title="Editar"
                  ></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Spinner />
      )}
    </div>
      <button className='btn-btn-primary' onClick={handleExport} >Exportar</button>
      {items.results?.length > 0 && (
              <Pagination
                prev={items.prev}
                next={items.next}
                total_pages={items.total_pages}
                changePage={changePage}
                page={page}
              />
            )}
    </div>
  )
}


