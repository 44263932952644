import React from 'react'

export const EqHoja=({datason})=> {
    const equipos=datason.equipos
  return (
    <div className="table-responsive">
    <div className="container mt-4">
      {equipos && equipos.length > 0 ? (
        <table className="table table-striped table-hover">
          <thead className="table-info">
            <tr>
              <th>Ítem</th>
              <th>Marca</th>
              <th>Modelo</th>
              <th>Serie</th>
              <th>Ubicación</th>
              <th>Inventario</th>
              <th>Consecutivo</th>
              <th>Responsable</th>
              <th>Estado</th>
            </tr>
          </thead>
          <tbody>
            {equipos.map((equipo, index) => (
              <tr key={index}>
                <td>{equipo.equipo.item.nombre}</td>
                <td>{equipo.equipo.marca}</td>
                <td>{equipo.equipo.modelo}</td>
                <td>{equipo.equipo.serie}</td>
                <td>{equipo.equipo.ubicacion}</td>
                <td>{equipo.equipo.inventario}</td>
                <td>{equipo.consecutivo}</td>
                <td>{equipo?.responsable?.nombre+" "+equipo?.responsable?.apellido.charAt(0)}</td>
                <td>{equipo.estado}</td>
              </tr>
            ))}
            <tr key={"observacion"}>
                <td colSpan="9">{datason?.observacion}</td>
            </tr>
          </tbody>
        </table>
      ) : (
        <div className="p-3 text-center bg-warning text-dark rounded border border-warning">
            <h3 className="m-0">
            No hay tienes ítems por finalizar. Seleccione un responsable o marque como procesado a los ítems deseados.
            </h3>
        </div>
      )}
    </div>
    </div>
  )
}
