import React, { useEffect } from 'react'
import { useState } from 'react';
import { MultiSelect } from "react-multi-select-component";
import { filterData, getDataList, postDataClientes } from '../../api/common';

let remapa=[]
export const SelectMult = ({handledata, collection, name, field, baseFilter, placeholder, dataDefault}) => {
    if(dataDefault?.length>0){
      remapa= name==="users"? 
      dataDefault.map(e=>({"label": e.nombre+" "+e.apellido,"value": e._id}))
      :
      dataDefault.map(e=>({"label": e.nombre,"value": e._id}))
    }else{
      remapa=[]
    }

    const [search, setSearch] = useState(null);
    const [options, setOptions]=useState([]);
    const [selected, setSelected]=useState(remapa)

    useEffect(()=>{
        async function consult(){
            const result = baseFilter? await filterData(`${collection}`,baseFilter)
            : await getDataList(`${collection}`);
            const opt=result.data.data.map((item)=>({
                value:item._id,
                label:collection==='Usuarios'?item[field]+" "+item['apellido']:item[field]
            }));
            setOptions(opt)
        }
        consult()
    },[])

    const mulsele=(e)=>{
      setSelected(e)
      let arraydata=[]
      for  (let i of e){
          arraydata.push(i.value)
          }
          console.log(arraydata)
      if(arraydata.length>0){
      let event = {
        target: {
          name: name,
          value: arraydata,
        },
      };
     // //console.log(event)
      handledata(event);
    }else{
      let event = {
        target: {
          name: name,
          value: null,
        },
      };
     // //console.log(event)
      handledata(event);
    }
  }
  return (
    <div>
        <MultiSelect
            placeholder={placeholder}
            options={options}
            value={selected}
            onChange={mulsele}
            labelledBy="Select"
      />
    </div>
  )
}

export default SelectMult
