import React from 'react'

const Form_1EqPatron = ({setPageForm, setEqBody, eqBody, setTitulo, crearEqPatron}) => {
let keys=[{field:'item', required:true},{field:'marca', required:true},{field:'modelo', required:true},{field:'serie', required:true},{field:'inventario', required:true},{field:'ubicacion', required:true}]
setTitulo('Información general')
const handleSubmit=async(e)=>{
    e.preventDefault();
    crearEqPatron()
}

console.log(eqBody)
const handledata=(event)=>{
    setEqBody({
        ...eqBody,
        [event.target.name]:event.target.value
    })
   }

   const selectTypeEquipo=(e)=>{
    console.log(e.target.value)
    if (e.target.value!=='todos'){
        setEqBody({
            ...eqBody,
            'tipoEquipo':e.target.value
        })
    }else{
        setEqBody({
            ...eqBody,
            'tipoEquipo':null
        })
    }
   }
   
   
return (
    <div>
        <form  className='form_container' type='submit' onSubmit={handleSubmit}>
        <div className='form_group ' >
        {keys.map((key, index)=>(
            <div key={index}>
                <label htmlFor={key.field} className='form_group_label'>{key.field.toLocaleUpperCase()+':' }</label>
                <input name={key.field} className='titulo w-100' type={key.tipo? key.tipo:'string' } required={key.required? key.required:false} onChange={handledata}defaultValue={eqBody && eqBody[key.field]? eqBody[key.field]:''}  /> 
            </div>
            ))}
            <label htmlFor="selectOpciones" className='form_group_label' required >TIPO DE EQUIPO:</label>
                  <select id="selectOpciones" className="form-select" value={eqBody?.tipoEquipo} onChange={selectTypeEquipo}>
                    <option value={"todos"}>Selecciona una opcion</option>
                    <option value="Patrón">Patrón</option>
                    <option value="Prestamo">Prestamo</option>
                    <option value="Auxiliar">Auxiliar</option>
                    <option value="Activo fijo">Activo fijo</option>
            </select>
            </div>
            <button type="submit" className="btn btn-primary form_group_element ">Aceptar</button>
            {/*<button className='btn-btn-secundary w-100' type='submit' onClick={()=>setPageForm(2)}>Siguiente</button>*/}
        </form>
    </div>
  )
}

export default Form_1EqPatron
