import { actualizarDatos, actualizarEqOt, getDataList } from '../../api/common'
import { PARENT } from '../../constants'
import { Alertassw } from '../Alertas/Alertassw'


export const FinalizarEq = async (eq, ots, visita, SetModalcharge, usuario, sitioSeleted) => { 
    SetModalcharge(true)  
    async function putItem() {
        try {
            const result = await actualizarDatos('EqInOt', {
                _id: eq,
                estado: 'Procesado',
                fechaProcesado: new Date(),
                responsable: usuario,
                visita: visita,
                numeroOrden: ots,
                sitioCal: sitioSeleted,
                change: true,      // Lo envio al backend para indicar que se debe registrar un cambio en la coleccion de cambios
                responsableChange: usuario,
            }, eq, ots);

            if (result.status === 200) {
                Alertassw('toast', 'Se ha registrado el cambio exitosamente cambiado');
                SetModalcharge(false);
                return result.data.data;
            }
        } catch (error) {
            SetModalcharge(false);
            Alertassw('error', 'Ha ocurrido un error', 'Refresca la ventana nuevamente, si el error persiste notificar a soporte técnico');
            throw error; // Importante para propagar el error si es necesario
        }
    }
    return await putItem();
}
export const  ActivarEq=(eq,ots,visita,SetModalcharge,usuario,orden,equipo)=> {
    async function putItem(){
        try {
            if(equipo.estado==='Pendiente' || equipo.estado==='Aplazado'){

            const result = await actualizarDatos('EqInOt',
            {
                _id:eq,
                estado:'No Finalizado',
                observacion:'',
                responsableChange:usuario,
                responsable:null,
                visita:visita,
                numeroOrden:ots,
                change:true
            },eq,ots)
            
            if(result.status===200){
                Alertassw('toast', 'Se ha registrado el cambio exitosamente')
                SetModalcharge(false)
                return result.data.data
            }
        }
        } catch (error) {
            Alertassw('error', 'Ha ocurrido un error', 'Refresca la ventana nuevamente, si el error persiste notificar a soporte técnico');
            SetModalcharge(false)     
    }
}
    return putItem();
}
export const  NoFinalizarEq=(eq, SetModalcharge,task)=> {

    async function putItem(){
        SetModalcharge(true)
        const result = await actualizarDatos('EqInOt',
        {
            _id:eq,
            estado:task==='revisar'?'Finalizado':'No Finalizado',
            visita:-1,
            ot:'',
            responsable:null
        },eq)

        if(result.status===200){
            Alertassw('toast', 'Se ha registrado el cambio exitosamente')
            SetModalcharge(false)
            return result.data.data
        }
    }
    return putItem();
}